import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import CONF from '../../config'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    table: {
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    pagination: {
      // '& > *': {
      //   paddingLeft: theme.spacing(40),
      // },
    },
    stockCode: {
      // layout
      display: 'block',
      maxWidth: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',

      // text
      color: 'rgb(15, 105, 255)',
      fontWeight: 700,
      fontSize: '14px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      fontStretch: '100%',
      textIndent: '0px',
      lineHeight: '18.2px',
      whiteSpace: 'nowrap',
      textDecorationLine: 'none',
      textDecorationStyle: 'solid',
      textDecorationThickness: 'auto',
      textOverflow: 'ellipsis',
      textSizeAdjust: '100%',

      // other
      textRendering: 'optimizeLegibility',
      // -webkit-font-smoothing antialiased
      hyphens: 'none',
      cursor: 'pointer',
    },
    tableCell: {
      // width: 10,
      padding: '0px 8px',
    },
    aiRatingNumberStyle: {
      color: 'rgb(0, 135, 60)',
      fontWeight: 700,
      fontSize: '0.85rem',
    },
    numberStyle: {
      // color: 'rgb(0, 135, 60)',
      fontWeight: 700,
      fontSize: '0.85rem',
    },
  })
)
