import { createState } from '@hookstate/core'
import { API } from '../../constant'
import { IAiInOut, IPerformanceAll } from './interface'
import { getAxios, postAxios } from '../../utils/parse'
import { DATA } from './AiPerformanceChart'

export const AiInOutState = {
  top: createState<number>(5),
  inOut: createState<IAiInOut>({ in: [], out: [] }),
  performance: createState<IPerformanceAll[]>([]),
  chartPeriod: createState<string>('All'),
}

export const AiInOutAction = {
  getInOut(top: number, date: string) {
    return async () => {
      try {
        // AiInOutState.inOut.set({ in: [], out: [] })

        const response = await postAxios(API.AI_RATING.getInOut, { top, date })

        AiInOutState.inOut.set(response)
      } catch (err) {
        AiInOutState.inOut.set({ in: [], out: [] })
      }
    }
  },

  getChartData(options, top, period) {
    return async () => {
      try {
        AiInOutState.performance.set([])

        const response = await postAxios(API.CHART.getPerformance, { top, period })
        // console.log(`====response====${response}`)
        DATA.aiRating = response.map((record) => {
          return {
            x: new Date(record.date).getTime(),
            y: record.aiRating * 100,
          }
        })
        DATA.vnIndex = response.map((record) => {
          return {
            x: new Date(record.date).getTime(),
            y: record.vnIndex * 100,
          }
        })
        options.series[0].data = DATA.aiRating
        options.series[1].data = DATA.vnIndex

        AiInOutState.performance.set(response)
      } catch (err) {
        AiInOutState.performance.set([])
      }
    }
  },
}
