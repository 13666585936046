import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useMobileStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      // paddingTop: '-10px'
      height: '2rem',
      // height: '4rem'
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
  })
)
