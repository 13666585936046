import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Grid from '@material-ui/core/Grid'
import AiTable from '../components/AiMainTable'
import AiInOutAndChart from '../components/AiInOutAndChart'

export default () => (
  <Layout>
    <SEO title="Home" />
    <Grid container spacing={3}>
      <Grid container item xs={12} md={8}>
        <AiTable />
      </Grid>
      <Grid container item xs={12} md={4} justify="center">
        <AiInOutAndChart />
      </Grid>
    </Grid>
  </Layout>
)
