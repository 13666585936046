import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useStyles } from './style'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import TableCell from '@material-ui/core/TableCell'
import { isScreenBiggerThan } from '../../../utils/parse'
import { useMobileStyles } from './mobileStyle'
import { Data, EnhancedTableProps, HeadCell } from '../interface'

export default function AiHeader(props: EnhancedTableProps) {
  const classes = useStyles()
  const mobileClasses = useMobileStyles()
  const { t, i18n } = useTranslation()

  const headCells: HeadCell[] = [
    { id: 'rank', numeric: false, disablePadding: false, label: t('Rank') },
    { id: 'code', numeric: false, disablePadding: false, label: t('Code') },
    // { id: 'change', numeric: true, disablePadding: false, label: 'Change' },
    { id: 'overall', numeric: true, disablePadding: false, label: t('Overall') },
    { id: 'techScore', numeric: true, disablePadding: false, label: t('Technical') },
    { id: 'valuationScore', numeric: true, disablePadding: false, label: t('Valuation') },
    { id: 'gsCore', numeric: true, disablePadding: false, label: t('Quality') },
  ]

  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return isScreenBiggerThan('md') ? (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            // align={'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography className={classes.typography} variant="body2" gutterBottom>
                {headCell.label}
              </Typography>

              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  ) : (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={mobileClasses.tableCell}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/*<TableSortLabel*/}
            {/*  className={mobileClasses.tableCell}*/}
            {/*  active={orderBy === headCell.id}*/}
            {/*  direction={orderBy === headCell.id ? order : 'asc'}*/}
            {/*  onClick={createSortHandler(headCell.id)}*/}
            {/*>*/}
            <Typography className={mobileClasses.typography} variant="body2" gutterBottom>
              {headCell.label}
            </Typography>

            {/*  {orderBy === headCell.id ? (*/}
            {/*    <span className={mobileClasses.visuallyHidden}>*/}
            {/*      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
            {/*    </span>*/}
            {/*  ) : null}*/}
            {/*</TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
