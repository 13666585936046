import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      // paddingTop: '-10px'
      // height: '1rem',
      // height: '4rem'
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
  })
)
