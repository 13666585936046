import { getAxios, postAxios } from '../../utils/parse'
import { API } from '../../constant'
import { createState } from '@hookstate/core'
import { IAiTableList } from './interface'

export const AiTableState = {
  list: createState<IAiTableList>({ data: [], page: 0, totalPage: 0, count: 0, errorMessage: '' }),
  date: createState<string>(''),
  page: createState<number>(0),
  filter: createState<string>('{}'),
  searchStock: createState<string>(''),
}

export const AiTableAction = {
  loadAiRating(pageNo: number = 0, limit: number = 10, sort: string = 'rank:asc', date: string = '', filter: any = {}) {
    return async () => {
      try {
        // AiTableState.list.set({ data: [], page: 0, totalPage: 0, count: 0, errorMessage: '' })
        // query
        const params = { start: pageNo * limit, limit, sort, date, filter }
        const aiRatingList = await postAxios(API.AI_RATING.getAiRatingList, params)
        // console.log(`======aiRatingList=====${JSON.stringify(aiRatingList)}`)
        const aiCount = await postAxios(API.AI_RATING.getAiRatingCount, params)

        // dispatch
        AiTableState.list.set({
          data: aiRatingList,
          page: pageNo,
          totalPage: Math.ceil(aiCount.count / limit),
          count: aiCount.count,
          errorMessage: '',
        })
      } catch (err) {
        console.log(err)
        AiTableState.list.set({ data: [], page: 0, totalPage: 0, count: 0, errorMessage: '' })
      }
    }
  },
}
