import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { ROUTES } from '../../../constant'
import { AiInOutState } from '../state'
import { useState } from '@hookstate/core'

export default function AiInOutToolbar() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const topState = useState(AiInOutState.top)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    topState.set(event.target.value as number)
  }

  return (
    <Toolbar>
      {/*<Tooltip title="Filter list">*/}
      <Tooltip title="">
        <Grid container spacing={3}>
          <Grid container item xs={7} justify="center" alignItems="center">
            <Link to={ROUTES.WHAT_IS_AI_RATING}>{t('WhatIsAiRating')}</Link>
          </Grid>
          <Grid container item xs={5} justify="flex-end" alignItems="center">
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={topState.get()}
                onChange={handleChange}
              >
                <MenuItem value={5}>Top 5</MenuItem>
                <MenuItem value={10}>Top 10</MenuItem>
                <MenuItem value={15}>Top 15</MenuItem>
                <MenuItem value={20}>Top 20</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Tooltip>
    </Toolbar>
  )
}
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
)
