import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
)
