import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'

export const useMobileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    tableCell: {
      // minWidth: 650,
      // tableLayout: 'fixed',
      padding: '0px 8px',
      // marginTop: '18px',
      height: '3rem',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    typography: {
      fontSize: '0.7rem',
    },
  })
)

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: '#2f80ed',
      // color: theme.palette.common.white,
    },
    body: {
      fontSize: '1rem',
    },
  })
)(TableCell)
