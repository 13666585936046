import React, { useEffect } from 'react'
import Highcharts from 'highcharts'
// import Highcharts from 'highcharts/highstock'

import HighchartsReact from 'highcharts-react-official'
import { useStyles } from './style'
import { getRest } from '../../../utils/parse'
import CONF from '../../../config'
import { useState } from '@hookstate/core'
import { AiInOutAction, AiInOutState } from '../state'

export const DATA: any = {
  aiRating: [],
  vnIndex: [],
}

export default function AiRatingPerformanceChart({ heightPercent }) {
  const classes = useStyles()
  const [time, setTime] = React.useState(0)

  const topState = useState(AiInOutState.top)
  const periodState = useState(AiInOutState.chartPeriod)

  const options = {
    chart: {
      type: 'line',
      height: `${heightPercent}%`,
      zoomType: 'x',
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        // format: '{value:%Y-%m-%d}',
        format: '{value:%m/%Y}',
      },
      // startOnTick: true,
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<b>{point.x:%Y/%m/%d}</b><br/>',
      // pointFormat: '{series.legendSymbol.element.outerHTML} {series.name}: {point.y:.2f} %<br/>',
      shared: true,
      pointFormatter: function () {
        var point: any = this,
          series = point.series,
          legendSymbol = "<svg width='20' height='20'>" + series.legendSymbol?.element.outerHTML + '</svg>'
        // @ts-ignore
        return legendSymbol + series.name + ': <b>' + point.y.toFixed(2) + '%' + '</b><br/>'
      },
    },

    series: [
      {
        name: 'Ai Rating',
        data: DATA.aiRating,
        color: CONF.css.green,
        marker: {
          enabled: false,
        },
      },
      {
        name: 'VNIndex',
        data: DATA.vnIndex,
        color: CONF.css.red,
        marker: {
          enabled: false,
        },
      },
    ],
  }

  useEffect(() => {
    AiInOutAction.getChartData(options, topState.get(), periodState.get())().then(() => setTime(+new Date()))
  }, [topState.get(), periodState.get()])

  // return <HighchartsReact highcharts={Highcharts} options={options} constructorType={'stockChart'} />
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
