import 'date-fns'
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useStyles } from './style'
import { AiTableState } from '../state'
import { useState } from '@hookstate/core'
import { convertStringToDate, formatDateToDisplay } from '../../../utils/parse'
import { DATE_TIME_DB } from '../../../constant'

export default function DatePicker() {
  const classes = useStyles()
  const dateState = useState(AiTableState.date)
  const listState = useState(AiTableState.list)
  const [dateValue, setDateValue] = React.useState<Date | null>(null)

  const handleDateChange = (date: Date | null) => {
    setDateValue(date)
    if (date != null) {
      dateState.set(formatDateToDisplay(date, DATE_TIME_DB))
    }
  }

  useEffect(() => {
    if (dateState.get() === '' && listState.data.length > 0) {
      setDateValue(convertStringToDate(listState.data[0].date.get(), DATE_TIME_DB))
    }
  }, [listState.data.length])

  useEffect(() => {
    if (dateValue == null && listState.data.length > 0) {
      setDateValue(convertStringToDate(listState.data[0].date.get(), DATE_TIME_DB))
    }
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          // label="Date"
          maxDate={new Date()}
          value={dateValue}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
