import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AiFooter from './AiFooter/AiFooter'
import AiHeader from './AiTableHeader/AiHeader'
import AiToolbar from './AiToolbar/AiToolbar'
import { useStyles } from './style'
import { navigate } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import { Data, Order } from './interface'
import CONF from '../../config'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { isScreenBiggerThan, paddingNumber } from '../../utils/parse'
import { useMobileStyles } from './mobileStyle'
import { AiTableAction, AiTableState } from './state'
import { useState } from '@hookstate/core'
import { getObj } from '../../utils/hookState'

export default function AiTable() {
  const classes = useStyles()
  const mobileClasses = useMobileStyles()

  //#region Hooks
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('rank')

  // rerender
  const listState = useState(AiTableState.list)
  const dateState = useState(AiTableState.date)
  const pageState = useState(AiTableState.page)
  const filterState = useState(AiTableState.filter)
  const searchStockState = useState(AiTableState.searchStock)

  // console.log(`=====getKey======${JSON.stringify(getLoginInfo())}`)
  // console.log(`======isDev======${isDev()}`)

  useEffect(() => {
    const sort = `${orderBy}:${order}`
    AiTableAction.loadAiRating(pageState.get(), 10, sort, dateState.get(), getObj(filterState))()
  }, [pageState.get(), orderBy, order, dateState.get(), filterState.get(), searchStockState.get()])

  useEffect(() => {
    if (dateState.get() === '' && listState.data.length > 0) {
      dateState.set(listState.data[0].date.get())
    }
  }, [listState.data.length])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (code) => {
    navigate(`/code/${code}`)
  }

  const renderAiRatingScore = (aiRating) => {
    if (aiRating >= 4) {
      return <Typography style={{ color: CONF.css.green, fontWeight: 700, fontSize: '0.85rem' }}>{aiRating}</Typography>
    } else if (aiRating > 3) {
      return <Typography style={{ color: CONF.css.black, fontWeight: 700, fontSize: '0.85rem' }}>{aiRating}</Typography>
    } else {
      return <Typography style={{ color: CONF.css.red, fontWeight: 700, fontSize: '0.85rem' }}>{aiRating}</Typography>
    }
  }

  return isScreenBiggerThan('md') ? (
    <Paper className={classes.paper} elevation={0}>
      <AiToolbar />
      {/*<Table className={classes.table} size="small" aria-label="simple table">*/}
      <Table className={classes.table} aria-label="simple table">
        <AiHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

        <TableBody>
          {listState.data.length > 0 ? (
            listState.data.map((row) => (
              <TableRow key={row.code.get()}>
                <TableCell align="center">
                  <div style={{ display: 'inline-block' }}>
                    <Typography className={classes.numberStyle}>{paddingNumber(row.rank.get())}</Typography>
                  </div>
                  {row.change.get() >= 0 ? (
                    <div style={{ color: CONF.css.green, display: 'inline-block', fontSize: '0.85rem' }}>
                      (
                      <ArrowUpwardIcon fontSize={'inherit'} />
                      <div
                        style={{
                          width: '25px',
                          color: CONF.css.green,
                          display: 'inline-block',
                          fontSize: '0.85rem',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {row.change.get()})
                      </div>
                    </div>
                  ) : (
                    <div style={{ color: CONF.css.red, display: 'inline-block', fontSize: '0.85rem' }}>
                      (<ArrowDownwardIcon fontSize={'inherit'} />
                      <div
                        style={{
                          width: '25px',
                          color: CONF.css.red,
                          display: 'inline-block',
                          fontSize: '0.85rem',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {row.change.get() * -1})
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center" onClick={() => handleClick(row.code.get())}>
                  <Typography className={classes.stockCode}>{row.code.get()}</Typography>
                </TableCell>
                {/*<TableCell align="right">*/}
                {/*  <Typography className={classes.numberStyle} >*/}
                {/*    {row.change}*/}
                {/*  </Typography>*/}
                {/*</TableCell>*/}
                <TableCell align="right">
                  {renderAiRatingScore(row.overall.get().toFixed(1))}
                  {/*<Button variant="outlined" color={'inherit'} style={{ borderColor: CONF.css.green, cursor: 'default' }}>*/}
                  {/*<Typography className={classes.aiRatingNumberStyle}>{row.overall.get().toFixed(1)}</Typography>*/}
                  {/*</Button>*/}
                </TableCell>
                <TableCell align="right">
                  <Typography className={classes.numberStyle}>{row.techScore.get()}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography className={classes.numberStyle}>{row.valuationScore.get().toFixed(1)}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography className={classes.numberStyle}>{row.gsCore.get()}</Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="right">No record</TableCell>
            </TableRow>
          )}
        </TableBody>

        <AiFooter />
      </Table>
    </Paper>
  ) : (
    <Paper className={mobileClasses.paper} elevation={0}>
      <AiToolbar />

      <Table className={mobileClasses.table} size="small" aria-label="simple table" style={{ tableLayout: 'auto' }}>
        <colgroup>
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        {/*<Table className={mobileClasses.table} aria-label="simple table">*/}
        <AiHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

        <TableBody>
          {listState.data.length > 0 ? (
            listState.data.map((row) => (
              <TableRow key={row.code.get()}>
                <TableCell className={mobileClasses.tableCell} align="center">
                  <div style={{ display: 'inline-block' }}>
                    <Typography className={mobileClasses.numberStyle}>{row.rank.get()}</Typography>
                  </div>
                  {/*{isScreenBiggerThan('xs') && row.change >= 0 ? (*/}
                  {/*  <Typography*/}
                  {/*    style={{ color: CONF.css.green, display: 'inline-block' }}*/}
                  {/*    variant="body2"*/}
                  {/*    gutterBottom*/}
                  {/*  >*/}
                  {/*    (*/}
                  {/*    <ArrowUpwardIcon fontSize={'inherit'} /> {row.change})*/}
                  {/*  </Typography>*/}
                  {/*) : (*/}
                  {/*  <Typography style={{ color: CONF.css.red, display: 'inline-block' }} >*/}
                  {/*    (<ArrowDownwardIcon fontSize={'inherit'} /> {row.change})*/}
                  {/*  </Typography>*/}
                  {/*)}*/}
                </TableCell>
                <TableCell
                  // className={mobileClasses.tableCell}
                  // style={{ width: '2rem' }}
                  align="center"
                  onClick={() => handleClick(row.code.get())}
                >
                  <Typography className={mobileClasses.stockCode}>{row.code.get()}</Typography>
                </TableCell>
                {/*<TableCell align="center">*/}
                {/*  <Typography className={mobileClasses.numberStyle} >*/}
                {/*    {row.change}*/}
                {/*  </Typography>*/}
                {/*</TableCell>*/}
                <TableCell className={mobileClasses.tableCell} align="right">
                  {/*<Button variant="outlined" color={'inherit'} style={{ borderColor: CONF.css.green }}>*/}
                  <Typography className={mobileClasses.aiRatingNumberStyle}>{row.overall.get().toFixed(1)}</Typography>
                  {/*</Button>*/}
                </TableCell>
                <TableCell className={mobileClasses.tableCell} align="right">
                  <Typography className={mobileClasses.numberStyle}>{row.techScore.get()}</Typography>
                </TableCell>
                <TableCell className={mobileClasses.tableCell} align="right">
                  <Typography className={mobileClasses.numberStyle}>{row.valuationScore.get().toFixed(1)}</Typography>
                </TableCell>
                <TableCell className={mobileClasses.tableCell} align="right">
                  <Typography className={mobileClasses.numberStyle}>{row.gsCore.get()}</Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="right">No record</TableCell>
            </TableRow>
          )}
        </TableBody>

        <AiFooter />
      </Table>
    </Paper>
  )
}
