import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 200,
    },
    input: {
      width: 42,
    },
    menu: {
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(1),
    },
    paper: {
      width: '100%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  })
)

// export const PrettoSlider = withStyles({
//   // root: {
//   //   // color: '#52af77',
//   //   color: CONF.css.blue,
//   //   height: 8,
//   // },
//   // thumb: {
//   //   height: 24,
//   //   width: 24,
//   //   backgroundColor: '#fff',
//   //   border: '2px solid currentColor',
//   //   marginTop: -8,
//   //   marginLeft: -12,
//   //   '&:focus, &:hover, &$active': {
//   //     boxShadow: 'inherit',
//   //   },
//   // },
//   // active: {},
//   // valueLabel: {
//   //   left: 'calc(-50% + 4px)',
//   // },
//   // track: {
//   //   height: 8,
//   //   borderRadius: 4,
//   // },
//   // rail: {
//   //   height: 8,
//   //   borderRadius: 4,
//   // },
// })(Slider)
