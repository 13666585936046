import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './style'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import { Checkbox, FormControlLabel, Menu, Paper } from '@material-ui/core'
import CONF from '../../../config'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import Slider from '@material-ui/core/Slider'
import { AiTableState } from '../state'
import { useState } from '@hookstate/core'
import { getObj, setObj } from '../../../utils/hookState'
import IconButton from '@material-ui/core/IconButton'
import FilterListIcon from '@material-ui/icons/FilterList'

const marks = [
  {
    value: 0,
    label: '0',
  },
  // {
  //   value: 2.5,
  //   label: '2.5',
  // },
  {
    value: 5,
    label: '5',
  },
]

function valuetext(value: number) {
  return `${value}°C`
}

export default function AiFilter() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const filterState = useState(AiTableState.filter)

  const [topAi, setTopAi] = React.useState<number | string | Array<number | string>>(143)
  const [techScore, setTechScore] = React.useState<number[]>([0, 5])
  const [valuationScore, setValuationScore] = React.useState<number[]>([0, 5])
  const [qualityScore, setQualityScore] = React.useState<number[]>([0, 5])
  const [overallScore, setOverallScore] = React.useState<number[]>([0, 5])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const [vn30Only, setVn30Only] = React.useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setTopAi(newValue)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTopAi(event.target.value === '' ? '' : Number(event.target.value))
  }

  // console.log(`=====filter=====${JSON.stringify(aiFilterReducer.data.filter)}`)

  const handleClickStartFilter = () => {
    handleClose()
    let newFilter = {
      ...getObj(filterState),
      ...{
        rank_lte: topAi,
        techScore_gte: techScore[0],
        techScore_lte: techScore[1],
        valuationScore_gte: valuationScore[0],
        valuationScore_lte: valuationScore[1],
        gsCore_gte: qualityScore[0],
        gsCore_lte: qualityScore[1],
        overall_gte: overallScore[0],
        overall_lte: overallScore[1],
      },
    }
    if (vn30Only) {
      newFilter = { ...newFilter, ...{ code_in: CONF.vn30List } }
    }
    setObj(filterState, newFilter)
  }

  const handleClickReset = () => {
    handleClose()
    setTopAi(143)
    setTechScore([0, 5])
    setValuationScore([0, 5])
    setQualityScore([0, 5])
    setOverallScore([0, 5])
    setVn30Only(false)
    setObj(filterState, {})
  }

  const handleVn30Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVn30Only(event.target.checked)
  }

  return (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Paper className={classes.paper} elevation={0}>
          <div>
            <Typography id="input-slider" gutterBottom>
              {t('Top')}
            </Typography>
            <Grid container spacing={2} alignItems="center">
              {/*<Grid item>*/}
              {/*  <VolumeUp />*/}
              {/*</Grid>*/}
              <Grid item xs>
                <Slider
                  value={typeof topAi === 'number' ? topAi : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  step={5}
                  // marks
                  min={5}
                  max={30}
                />
              </Grid>
              <Grid item>
                <Input
                  className={classes.input}
                  value={topAi}
                  margin="dense"
                  onChange={handleInputChange}
                  // onBlur={handleBlur}
                  inputProps={{
                    step: 5,
                    min: 5,
                    max: 30,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                />
              </Grid>
            </Grid>

            <Typography id="range-slider" gutterBottom>
              {t('Technical Score')}
            </Typography>
            <Slider
              value={techScore}
              onChange={(event, newValue) => setTechScore(newValue as number[])}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              step={0.5}
              marks={marks}
              min={0}
              max={5}
            />

            <Typography id="range-slider" gutterBottom>
              {t('Valuation Score')}
            </Typography>
            <Slider
              value={valuationScore}
              onChange={(event, newValue) => setValuationScore(newValue as number[])}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              step={0.5}
              marks={marks}
              min={0}
              max={5}
            />

            <Typography id="range-slider" gutterBottom>
              {t('Quality Score')}
            </Typography>
            <Slider
              value={qualityScore}
              onChange={(event, newValue) => setQualityScore(newValue as number[])}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              step={0.5}
              marks={marks}
              min={0}
              max={5}
            />

            <Typography id="range-slider" gutterBottom>
              {t('Overall')}
            </Typography>
            <Slider
              value={overallScore}
              onChange={(event, newValue) => setOverallScore(newValue as number[])}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              step={0.5}
              marks={marks}
              min={0}
              max={5}
            />
          </div>

          <FormControlLabel
            control={<Checkbox checked={vn30Only} onChange={handleVn30Change} name="checkedB" color="primary" />}
            label="VN30"
          />
          <div></div>
          <Button
            variant="outlined"
            color={'inherit'}
            style={{ borderColor: CONF.css.green }}
            onClick={handleClickReset}
          >
            <Typography variant="body2" gutterBottom>
              {t('Reset')}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            color={'inherit'}
            style={{ borderColor: CONF.css.green }}
            onClick={handleClickStartFilter}
          >
            <Typography variant="body2" gutterBottom>
              {t('Start Filter')}
            </Typography>
          </Button>
        </Paper>
      </Menu>
    </>
  )
}
