import React, { useEffect } from 'react'
import { Menu, TextField } from '@material-ui/core'
import DatePicker from './DatePicker'
import Grid from '@material-ui/core/Grid'
import FilterListIcon from '@material-ui/icons/FilterList'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useStyles } from './style'
import AiFilter from '../AiFilter/AiFilter'
import { useTranslation } from 'react-i18next'
import { isScreenBiggerThan } from '../../../utils/parse'
import { AiTableState } from '../state'
import { useState } from '@hookstate/core'
import { getObj, setObj } from '../../../utils/hookState'
import { useMobileStyles } from './mobileStyle'

export default function AiToolbar() {
  const classes = useStyles()
  const classesMobile = useMobileStyles()
  const { t, i18n } = useTranslation()
  const filterState = useState(AiTableState.filter)
  const pageState = useState(AiTableState.page)
  const searchStockState = useState(AiTableState.searchStock)

  const handleStockChange = (input: string) => {
    const upperStock = input.toUpperCase()
    searchStockState.set(upperStock)

    if (searchStockState.get().length >= 3) {
      pageState.set(0)
      setObj(filterState, { ...getObj(filterState), ...{ code: upperStock } })
      // filterState.set({ ...getJson(filterState), ...{ code: upperStock } })
    } else if (searchStockState.get().length === 0) {
      const { code, ...newObj } = getObj(filterState)
      setObj(filterState, newObj)
    }
  }

  useEffect(() => {
    searchStockState.set('')
    const { code, ...newObj } = getObj(filterState)
    setObj(filterState, newObj)
  }, [])

  return isScreenBiggerThan('md') ? (
    <Toolbar>
      {/*<Tooltip title="Filter list">*/}
      <Tooltip title="">
        <Grid container spacing={3}>
          <Grid container item sm={3} justify="center" alignItems="center">
            {/*<GetAppIcon />*/}
            {/*EXPORT*/}
          </Grid>
          <Grid container item xs={4} sm={3} justify="flex-end">
            <TextField
              type="search"
              inputProps={{
                style: {
                  textTransform: 'uppercase',
                },
              }}
              label={t('Stock')}
              value={searchStockState.get()}
              onChange={(e) => handleStockChange(e.target.value)}
            />
          </Grid>
          <Grid container item xs={4} sm={3} justify="flex-end" alignItems="center">
            <DatePicker />
          </Grid>
          <Grid container item xs={4} sm={3} justify="center" alignItems="center">
            <AiFilter />
            {t('ADD FILTER')}
          </Grid>
        </Grid>
      </Tooltip>
    </Toolbar>
  ) : (
    <Toolbar className={classesMobile.toolbar} variant={'dense'}>
      {/*<Tooltip title="Filter list">*/}
      {/*<Tooltip title="">*/}
      <Grid container spacing={3} justify="flex-end">
        <Grid container item sm={3} />
        <Grid container item xs={4} sm={3} justify="flex-end" alignItems="flex-start">
          <TextField
            type="search"
            inputProps={{
              style: {
                textTransform: 'uppercase',
              },
            }}
            label={t('Stock')}
            value={searchStockState.get()}
            onChange={(e) => handleStockChange(e.target.value)}
          />
        </Grid>
        <Grid container item xs={6} sm={3} justify="flex-end" alignItems="center">
          <DatePicker />
        </Grid>
        <Grid container item xs={2} sm={2} justify="center" alignItems="flex-end">
          <AiFilter />
          {/*{t('ADD FILTER')}*/}
        </Grid>
      </Grid>
      {/*</Tooltip>*/}
    </Toolbar>
  )
}
