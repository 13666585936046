import { createStyles, makeStyles } from '@material-ui/core/styles'
import CONF from '../../config'

export const useMobileStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(0),
    },
    chartPaper: {
      width: '100%',
      marginBottom: theme.spacing(15),
      marginTop: theme.spacing(3),
    },
    nameAndExchangeTypo: {
      paddingLeft: 25,
      paddingTop: 20,
      paddingBottom: 10,
      color: '#2f80ed',
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },

    aiRatingTypo: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      // paddingLeft: 25,
      // paddingTop: 15,
      paddingBottom: 0,
      color: CONF.css.blue,
      // fontWeight: 700,
      fontSize: '1rem',
      cursor: 'pointer',
    },
    performanceTypo: {
      marginLeft: theme.spacing(2),
      color: CONF.css.black,
    },
  })
)
