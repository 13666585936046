import { createStyles, makeStyles } from '@material-ui/core/styles'
import CONF from '../../config'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    chartPaper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      // marginTop: theme.spacing(2),
    },
    aiRatingTypo: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      // paddingLeft: 25,
      // paddingTop: 15,
      paddingBottom: 0,
      color: CONF.css.blue,
      // fontWeight: 700,
      fontSize: '1rem',
      cursor: 'pointer',
    },
    nameAndExchangeTypo: {
      // marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: 15,
      color: CONF.css.blue,
      // fontWeight: 700,
      fontSize: '1.3rem',
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
    performanceTypo: {
      marginLeft: theme.spacing(2),
      color: CONF.css.black,
    },
    selectPeriod: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  })
)
