import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AiInOutToolbar from '../AiInOutToolbar/AiInOutToolbar'
import { StyledTableCell, StyledTableCellRed, StyledTableRow, useStyles } from './style'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { AiTableState } from '../../AiMainTable/state'
import { useState } from '@hookstate/core'
import { AiInOutAction, AiInOutState } from '../state'

export default function AiInOut() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const dateState = useState(AiTableState.date)
  const topState = useState(AiInOutState.top)
  const inOutState = useState(AiInOutState.inOut)

  useEffect(() => {
    if (dateState.get() !== '') {
      AiInOutAction.getInOut(topState.get(), dateState.get())()
    }
  }, [topState.get(), dateState.get()])

  let inList = ''
  if (inOutState.in.get().length > 0) {
    inList = inOutState.in.get().join(', ')
  }
  let outList = ''
  if (inOutState.out.get().length > 0) {
    outList = inOutState.out.get().join(', ')
  }
  const headers = ['In', 'Out']

  return (
    // <Paper className={classes.paper}>
    <>
      <AiInOutToolbar />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((label) => (
              <StyledTableCell key={label} align="center">
                <Typography className={classes.tableHeader} variant="body2" gutterBottom>
                  {t(label)}
                </Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow key={'in'}>
            <TableCell component="th" scope="row" align="center">
              <Typography className={classes.stockInTypo} variant="body2" gutterBottom>
                {inList}
              </Typography>
            </TableCell>
            <TableCell width={'10px'} align="center">
              <Typography className={classes.stockOutTypo} variant="body2" gutterBottom>
                {outList}
              </Typography>
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </>
  )
}
