import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
    tableHeader: {
      // color: '#0F69FF',
      // fontWeight: 400,
      // fontSize: '11px',
      // display: 'block',
      // fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      // textAlign: 'center',
      // whiteSpace: 'nowrap',
      //
      // fontStretch: '100%',
      // textIndent: '0px',
      // lineHeight: '14.3px',
    },
    stockInTypo: {
      color: 'rgb(0, 135, 60)',
      fontWeight: 700,
      fontSize: '14px',
      display: 'block',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      fontStretch: '100%',
      textIndent: '0px',
      lineHeight: '18.2px',
    },
    stockOutTypo: {
      color: 'rgb(235, 15, 41)',
      fontWeight: 700,
      fontSize: '14px',
      display: 'block',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      fontStretch: '100%',
      textIndent: '0px',
      lineHeight: '18.2px',
    },
  })
)

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: '#27c305',
      // color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

export const StyledTableCellRed = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: '#ff0000',
      // color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // '&:nth-of-type(odd)': {
      //   backgroundColor: theme.palette.action.hover,
      // },
    },
  })
)(TableRow)
