import React from 'react'
import Paper from '@material-ui/core/Paper'
import AiRatingPerformanceChart from './AiPerformanceChart'
import AiInOut from './AiInOut/AiInOut'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './style'
import { useTranslation } from 'react-i18next'
import { isScreenBiggerThan } from '../../utils/parse'
import { useMobileStyles } from './styleMobile'
import { useState } from '@hookstate/core'
import { AiInOutState } from './state'
import { EDUCATION_POST, ROUTES } from '../../constant'
import { navigate } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { LanguageState } from '../Layout/Header/state'

export default function AiInOutAndChart() {
  const classes = useStyles()
  const classesMobile = useMobileStyles()
  const { t, i18n } = useTranslation()

  const topState = useState(AiInOutState.top)
  const periodState = useState(AiInOutState.chartPeriod)

  const AiInOutAndHowToUseComponent = (styles = classes) => (
    <>
      <Paper className={styles.paper} elevation={0}>
        <AiInOut />
      </Paper>

      {/*<Grid container spacing={3}>*/}
      <Grid container item justify={'flex-end'}>
        <Typography
          className={styles.aiRatingTypo}
          onClick={() => {
            if (LanguageState.language.get() === 'vi') {
              navigate(`${ROUTES.EDUCATION}${EDUCATION_POST.HowToUseVi}`)
            } else {
              navigate(`${ROUTES.EDUCATION}${EDUCATION_POST.HowToUseEn}`)
            }
          }}
        >
          {t('How to use AI Rating?') + ' >>'}
        </Typography>
      </Grid>
      {/*</Grid>*/}
    </>
  )

  // console.log(`====periodState.get()====${periodState.get()}`)
  const ChartComponent = (styles = classes) => (
    <>
      <Paper className={styles.chartPaper} elevation={0}>
        <Grid container spacing={3}>
          <Grid container item xs={7}>
            <Typography className={styles.nameAndExchangeTypo}>{`${t(
              'Performance'
            )} - Top ${topState.get()}`}</Typography>
          </Grid>
          <Grid container item xs={5} justify={'flex-end'}>
            <FormControl variant="outlined" className={classes.selectPeriod}>
              {/*<InputLabel id="demo-simple-select-label">Top 5</InputLabel>*/}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={periodState.get()}
                onChange={(e) => periodState.set(e.target.value as string)}
              >
                <MenuItem value={'All'}>{t('All')}</MenuItem>
                <MenuItem value={'1W'}>1 {t('Week')}</MenuItem>
                <MenuItem value={'1M'}>1 {t('Month')}</MenuItem>
                <MenuItem value={'3M'}>3 {t('Month')}</MenuItem>
                <MenuItem value={'6M'}>6 {t('Month')}</MenuItem>
                <MenuItem value={'1Y'}>1 {t('Year')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <AiRatingPerformanceChart heightPercent={80} />
        <Typography className={styles.performanceTypo}>{t('PerformanceNote')}</Typography>
      </Paper>
    </>
  )

  return isScreenBiggerThan('sm') ? (
    <>
      {AiInOutAndHowToUseComponent(classes)}
      {ChartComponent(classes)}
    </>
  ) : (
    <>
      {AiInOutAndHowToUseComponent({ ...classes, ...classesMobile })}
      {ChartComponent({ ...classes, ...classesMobile })}
    </>
  )
}
